import * as React from "react";
import { List, Datagrid, SimpleList, BooleanField, EmailField, TextField, BooleanInput, SimpleForm, TextInput, NumberInput, Edit } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';

export const UserList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List {...props}>
            {isSmall ?
                <SimpleList
                    primaryText={record => record && record.username}
                    secondaryText={record => record && `${record.email}`}
                    
                />
                : <Datagrid rowClick="edit">
                    <TextField source="username" />
                    <EmailField source="email" />
                    <BooleanField source="confirmed" />
                    <TextField source="role.name" label='Role' />
                </Datagrid>}
        </List>
    )
};

export const UserEdit = props => (
    <Edit {...props} undoable={false}>
        <SimpleForm>
            <TextInput source="id" />
            <TextInput source="username" />
            <TextInput source="password" />
            <TextInput source="email" />
            <BooleanInput source="confirmed" />
            <BooleanInput source="blocked" />
            <NumberInput source="role.id" />
        </SimpleForm>
    </Edit>
);