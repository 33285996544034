import React, { useState } from 'react'
import ButtonMUI from '@material-ui/core/Button';

function Form(props) {
  const [msg, setMsg] = useState("")

  function sendSms(number) {

    let data = {
      push: {
        conversation_iden: number,
        message: msg,
        package_name: "com.pushbullet.android",
        source_user_iden: "uju3xnTbzQi",
        target_device_iden: "uju3xnTbzQisjESBmyFWsC",
        type: "messaging_extension_reply"
      },
      type: "push"
    }

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log(this.responseText);
      }
    });

    xhr.open("POST", "https://api.pushbullet.com/v2/ephemerals");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Authorization", "Bearer o.7Lx0Mr2bGXc3HpwEMxkIPHA13Fvo6BZy");
    xhr.send(JSON.stringify(data));
    console.log("Poslao na: " + number)
  }


  const handleClick = () => {
    props.numbers.map(x => sendSms(x))
  }



  const handleChange = (e) => {
    setMsg(e.target.value)
  }
  return (
    <div style={{ display: 'flex' }}>
      <ButtonMUI style={{ margin: '20px' }} variant="contained" color="primary" onClick={handleClick}>
        Send
    </ButtonMUI>
      <textarea type="text" value={msg} onChange={handleChange} />
    </div>
  )
}


export default Form