import * as React from "react";
import { List, Datagrid, usePermissions, TextField, ReferenceInput, SelectInput, NumberInput, SimpleList, DateField, NumberField, SimpleForm, TextInput, Edit, Filter } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { DateInput } from '../components/DateInput'
import { userContext } from '../context/Context'

const PaymentFilter = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    return (
        <Filter {...props}>
            <TextInput source="_q" label="Search" alwaysOn />
            <DateInput source='created_at_gte' label="From" alwaysOn={isSmall ? false : true} options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            <DateInput source='created_at_lte' label="To" alwaysOn={isSmall ? false : true} options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            {    permissions === 'SuperAdmin' && <ReferenceInput label="Coach" source="coach" reference="users">
                <SelectInput optionText="username" optionValue='id' />
            </ReferenceInput>}
        </Filter>
    )
};

export const PaymentList = ({ permissions, ...props }) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [userMe] = React.useContext(userContext)

    return (
        <List bulkActionButtons={false} sort={{ field: 'created_at', order: 'DESC' }} filter={permissions !== 'SuperAdmin' ? { coach: userMe && userMe.id } : {}} filters={<PaymentFilter />}  {...props}>
            {isSmall ?
                <SimpleList
                    primaryText={record => record && record.description + ' RSD'}
                    secondaryText={record => record && moment(record.startDate).format('D/MM/YYYY') + ' to ' + moment(record.endDate).format('D/MM/YYYY')}
                    tertiaryText={record => record && moment(record.created_at).format('D/MM/YYYY hh:m')}
                />
                :
                <Datagrid rowClick="edit">
                    <NumberField source="amount" />
                    <TextField source="description" />
                    <DateField source="created_at" locales="fr-FR" />
                    <DateField source="startDate" locales="fr-FR" />
                    <DateField source="endDate" locales="fr-FR" />
                    <TextField source="coach.username" label="Coach" sortable={false} />
                    <TextField source="fiscalNumber" label="Fiscal" />
                </Datagrid>}
        </List>
    )
}

export const PaymentEdit = props => (
    <Edit {...props} undoable={false}>
        <SimpleForm>
            <NumberInput source="amount" />
            <TextInput source="description" />
            <DateInput source="startDate" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
            <DateInput source="endDate" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
            <DateInput source="created_at" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
            <TextInput source="fiscalNumber" />
        </SimpleForm>
    </Edit>
);
