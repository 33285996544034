import * as React from "react";
import { List, Create, SimpleList, Datagrid, Filter, TextField, DateField, NumberField, FormDataConsumer, SimpleForm, TextInput, NumberInput, Edit, ReferenceInput, SelectInput } from 'react-admin';
import DatePicker from '../components/DateTimePicker'
import { useForm } from 'react-final-form';
import Box from '@material-ui/core/Box';
import { useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { DateInput } from '../components/DateInput'

const Title = ({ record }) => {
    return <span>{record.coach.username}</span>;
};

const SaleriesFilter = (props) => {

    return (
        <Filter {...props}>
            <TextInput source="_q" label="Search" alwaysOn />
            <DateInput source='created_at_gte' label="From" alwaysOn options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            <DateInput source='created_at_lte' label="To" alwaysOn options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
        </Filter>
    )
};

export const SalaryList = props => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List sort={{ field: 'created_at', order: 'DESC' }} filters={<SaleriesFilter />} {...props}>
            {isSmall ?
                <SimpleList
                    primaryText={record => record.coach.username}
                    secondaryText={record => moment(record.startDate).format('D/MM/YYYY') + ' - ' + moment(record.endDate).format('D/MM/YYYY')}
                    tertiaryText={record => record.total + ' RSD'}
                /> : <Datagrid rowClick="edit">
                    <TextField source="coach.username" label='Coach' />
                    <DateField source="startDate" locales="fr-FR" />
                    <DateField source="endDate" locales="fr-FR" />
                    <NumberField source="totalHours" label="Working hours (h)" />
                    <NumberField source="salary" label="Per hour" />
                    <NumberField source="bonus" />
                    <NumberField source="total" />
                    <DateField source="created_at" locales="fr-FR" />
                </Datagrid>}
        </List>
    )
};

export const SalaryEdit = props => (
    <Edit title={<Title />}  {...props} undoable={false}>
        <SimpleForm>
            <TextInput source="coach.username" label='Coach' disabled />
            <DatePicker source="startDate" type="dateOnly" />
            <DatePicker source="endDate" type="dateOnly" />
            <DateInput source="startDate" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
            <DateInput source="endDate" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
            <NumberInput source="totalHours" label="Working hours (h)" inputVariant="filled" margin="dense" />
            <NumberInput source="salary" label="Per hour" />
            <NumberInput source="bonus" />
            <NumberInput source="total" />
            <DateInput source="created_at" disabled options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
        </SimpleForm>
    </Edit>
);

export const SalaryCreate = props => {

    const SalaryInput = props => {

        const form = useForm()

        return (
            <NumberInput label="Per hour" {...props} onChange={(e) => form.change('total', e.target.value * props.formData.totalHours + props.formData.bonus)} />
        )
    }

    const TotalHoursInput = props => {
        const form = useForm()
        return (
            <NumberInput {...props} onChange={(e) => form.change('total', e.target.value * props.formData.salary + props.formData.bonus)} />
        )
    }

    const BonusInput = props => {
        const form = useForm()
        return (
            <NumberInput {...props} onChange={(e) => form.change('total', parseFloat(e.target.value) + props.formData.salary * props.formData.totalHours)} />
        )
    }

    return (
        <Create {...props} undoable={false}>
            <SimpleForm redirect="list">
                <ReferenceInput label="Coach" source="coach" reference="users">
                    <SelectInput optionText="username" optionValue='id' />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData }) => <>
                        {formData.coach && <React.Fragment>
                            <Box display='flex' flexDirection='column' width='260px'>
                                <Box marginBottom="25px">
                                    <DatePicker source="startDate" type="dateOnly" label="Start date" formData={formData} coachID={formData.coach} />
                                </Box>
                                <Box marginBottom="25px">
                                    <DatePicker source="endDate" type="dateOnly" label="End date" formData={formData} coachID={formData.coach} />
                                </Box>
                                <TotalHoursInput source="totalHours" label="Working hours (h)" defaultValue={0} formData={formData} />
                                <SalaryInput source="salary" defaultValue={0} formData={formData} />
                                <BonusInput source="bonus" defaultValue={0} formData={formData} />
                                <NumberInput source="total" defaultValue={0} />
                            </Box>
                        </React.Fragment>}
                    </>}
                </FormDataConsumer>


            </SimpleForm>
        </Create>
    )
}