/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { List, Datagrid, SimpleList, TextField, DateField, NumberField, SimpleForm, TextInput, NumberInput, Edit, Filter } from 'react-admin';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import { getFinances } from '../Services/Services'
import { DateInput } from '../components/DateInput'

const Aside = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [totalIncomes, setTotalIncomes] = React.useState(0)
    const [totalCosts, setTotalCosts] = React.useState(0)

    React.useEffect(() => {
        getFinances(props.filterValues).then(data => {
            const incomes = data && data.map(finance => finance.type === 'income' ? finance.amount : 0)
            const costs = data && data.map(finance => finance.type === 'cost' ? finance.amount : 0)
            setTotalIncomes(incomes.reduce((a, b) => a + b, 0))
            setTotalCosts(costs.reduce((a, b) => a + b, 0))
        })
    }, [props.filterValues])

    return (<> { isSmall ? <div style={{ width: 130, margin: '1em' }} >
        Income: <br /> {totalIncomes} RSD <br />
            Outcome: <br /> {totalCosts} RSD <br />
                Total: <br /> {totalIncomes - totalCosts} RSD</div>
        :
        <div style={{ width: 200, margin: '1em' }}>
            Total income: {totalIncomes.toFixed(2)} RSD <br />
            Total outcome: {totalCosts.toFixed(2)} RSD <br />
            Total: {(totalIncomes - totalCosts).toFixed(2)} RSD </div>} </>
    )
};

const FinanceFilter = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="_q" label="Search" alwaysOn />
            <DateInput source='created_at_gte' label="From" alwaysOn options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            <DateInput source='created_at_lte' label="To" alwaysOn options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
        </Filter>
    )
};

export const FinanceList = props => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List aside={<Aside />} sort={{ field: 'created_at', order: 'DESC' }} filters={<FinanceFilter />} filterDefaultValues={{ created_at_gte: moment().format("YYYY-MM-01") }} bulkActionButtons={false} {...props}>
            {isSmall ?
                <SimpleList
                    primaryText={record => record.desciption + ' RSD'}
                    secondaryText={record => moment(record.created_at).format('DD/MM/YYYY') + ' - ' + record.type}
                />
                : <Datagrid rowClick="edit">
                    <TextField source="desciption" />
                    <TextField source="type" />
                    <NumberField source="amount" />
                    <DateField source="created_at" locales="fr-FR" />
                </Datagrid>}
        </List>
    )
};

export const FinanceEdit = props => (
    <Edit {...props} undoable={false}>
        <SimpleForm>
            <TextInput source="desciption" />
            <TextInput source="type" />
            <NumberInput source="amount" />
            <DateInput source="created_at" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
        </SimpleForm>
    </Edit>
);