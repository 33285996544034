/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { SelectArrayInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Dialog } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-final-form';
import { getTopics } from '../Services/Services'

export const GenerateTopicDialog = props => {

    const [topicsArray, setTopicArray] = React.useState({
        Technique: [],
        Movements: [],
        Concepts: [],
        Psychology: [],
        Abilities: []
    })
    const [value, setValue] = React.useState({
        Technique: [],
        Movements: [],
        Concepts: [],
        Psychology: [],
        Abilities: []
    })
    const form = useForm()

    const handleGenerateClick = () => {

        props.setOpenTopic(false)

        const topic = () => {

            const arrayTopic = [...value.Technique, ...value.Movements, ...value.Concepts, ...value.Psychology, ...value.Abilities]
            const res = arrayTopic.filter(x => x !== '' && x)
            let text = ''
            const length = res.length

            res.forEach((x, index) => {
                if (length === index) {
                    text += ' + ' + text
                } else if (index === 0) {
                    text += x
                } else text += ' + ' + x
            })
            return text
        }
        form.change('topic', topic())
    }

    React.useEffect(() => {

        getTopics(props.coachID).then(data => {
            const fetchedArrTechnique = data.filter(topic => topic.type === 'Technique').map(topic => ({ id: topic.Name, name: topic.Name }))
            const fetchedArrMovements = data.filter(topic => topic.type === 'Movements').map(topic => ({ id: topic.Name, name: topic.Name }))
            const fetchedArrConcepts = data.filter(topic => topic.type === 'Concepts').map(topic => ({ id: topic.Name, name: topic.Name }))
            const fetchedArrPsychology = data.filter(topic => topic.type === 'Psychology').map(topic => ({ id: topic.Name, name: topic.Name }))
            const fetchedArrAbilities = data.filter(topic => topic.type === 'Abilities').map(topic => ({ id: topic.Name, name: topic.Name }))
            setTopicArray({ Movements: fetchedArrMovements, Technique: fetchedArrTechnique, Concepts: fetchedArrConcepts, Psychology: fetchedArrPsychology, Abilities: fetchedArrAbilities })
        })
    }, [])

    return (<Dialog open={props.openTopic} fullWidth >
        <Box style={{ margin: '10px' }}>
            <Box display='flex' flexDirection='column'>

                <SelectArrayInput style={{ marginRight: '20px' }} allowEmpty label="Technique" source="Technique" onChange={(e) => setValue({ ...value, Technique: e.target.value })} choices={topicsArray.Technique} />
                <SelectArrayInput style={{ marginRight: '20px' }} allowEmpty label="Movements" source="Movements" onChange={(e) => setValue({ ...value, Movements: e.target.value })} choices={topicsArray.Movements} />
                <SelectArrayInput style={{ marginRight: '20px' }} allowEmpty label="Concepts" source="Concepts" onChange={(e) => setValue({ ...value, Concepts: e.target.value })} choices={topicsArray.Concepts} />
                <SelectArrayInput style={{ marginRight: '20px' }} allowEmpty label="Psychology" source="Psychology" onChange={(e) => setValue({ ...value, Psychology: e.target.value })} choices={topicsArray.Psychology} />
                <SelectArrayInput style={{ marginRight: '20px' }} allowEmpty label="Abilities" source="Abilities" onChange={(e) => setValue({ ...value, Abilities: e.target.value })} choices={topicsArray.Abilities} />
            </Box>
            <Box display='flex' justifyContent='end' paddingTop='30px'>
                <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={handleGenerateClick}>
                    Generate
                                </Button>

                <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => props.setOpenTopic(false)}>
                    Close
                                </Button>
            </Box>
        </Box>
    </Dialog>
    )
};

