import * as React from "react";
import moment from 'moment';

export const DateTimeCustom = ({ record = {} }) => {
    return (<span>{moment(record.appointment).format('D/MM/YYYY, h:mm a')} <br /> {moment(record.appointment).format('dddd')}</span>)
};

DateTimeCustom.defaultProps = { label: 'Appointment' };




