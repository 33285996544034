import React from 'react';
import TableListPayment from '../components/TableListPayment'
import { getPayments } from '../Services/Services'

export default function ListPayments(props) {

    const [studentPayment, setStudentPayment] = React.useState()

    React.useEffect(() => {

        getPayments(props.studentsID).then(data => {
            setStudentPayment(data)
        })
    }, [])

    return (<>
        {
            studentPayment && <TableListPayment
                fields={['Amount', 'Period', 'Coach']}
                data={studentPayment} />}
    </>
    )
}
