
import * as React from "react";
import { Dialog } from '@material-ui/core';
import {
    Button,
} from 'react-admin';
import IconEvent from '@material-ui/icons/Event';
import ButtonMUI from '@material-ui/core/Button';
import Table from './Table'
import Box from '@material-ui/core/Box';

const SendSMS = (props) => {

    const [openDialog, setOpenDialog] = React.useState()

    return (<>
        <Dialog open={openDialog} fullWidth>
            <Box maxWidth="md">
                <Table filterValues={props.filterValues} />
            </Box>
            <ButtonMUI style={{ margin: '20px' }} variant="contained" color="primary" onClick={() => setOpenDialog(false)}>
                Close
    </ButtonMUI>
        </Dialog>
        <Button
            onClick={() => { setOpenDialog(true) }}
            label="SMS"
        >
            <IconEvent />
        </Button>
    </>)

}

export default SendSMS