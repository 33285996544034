import * as React from "react";
import { List, useListContext, TopToolbar, CreateButton, Button, sanitizeListRestProps, Create, Datagrid, ReferenceField, TextField, ReferenceInput, SelectInput, NumberInput, SimpleList, DateField, NumberField, SimpleForm, TextInput, Edit, Filter } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { DateInput } from '../components/DateInput'
import { getCampsPayment } from '../Services/Services'
import ReceiptIcon from '@material-ui/icons/Receipt';
import { generateCampInvoices } from '../Services/Services'

const Title = ({ record }) => {
    return <span>{record.student.firstName + ' ' + record.student.lastName}</span>;
};

const Aside = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [totalIncomes, setTotalIncomes] = React.useState(0)

    React.useEffect(() => {
        getCampsPayment(props.filterValues).then(data => {
            const incomes = data && data.map(camppayment => camppayment.amount ? parseFloat(camppayment.amount) : 0)
            setTotalIncomes(incomes.reduce((a, b) => a + b, 0))
        })
    }, [props.filterValues])

    return (<> { isSmall ? <div style={{ width: 130, margin: '1em' }} >
        Income: <br /> {totalIncomes && totalIncomes.toFixed(2)} RSD <br />
    </div>
        :
        <div style={{ width: 200, margin: '1em' }}>
            Income: {totalIncomes && totalIncomes.toFixed(2)} RSD <br />
        </div>} </>
    )
};

const ListActions = (props) => {

    const {
        className,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <Button
                onClick={() => {
                    generateCampInvoices().then(res => alert(res.msg))
                }}
                label="Invoices"
            >
                <ReceiptIcon />
            </Button>


        </TopToolbar>
    );
};

const CampFilter = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Filter {...props}>
            <TextInput source="_q" label="Search" alwaysOn />
            <DateInput source='created_at_gte' label="From" alwaysOn={isSmall ? false : true} options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            <DateInput source='created_at_lte' label="To" alwaysOn={isSmall ? false : true} options={{ format: 'DD/MM/YYYY' }} inputVariant="standard" margin="normal" />
            <ReferenceInput label="Student" source="student" reference="students" perPage={200} sort={{ field: 'firstName', order: 'ASC' }} filter={{ active: true }}>
                <SelectInput optionText={record => record.firstName + ' ' + record.lastName} optionValue='id' />
            </ReferenceInput>
        </Filter>
    )
};

export const CampList = ({ permissions, ...props }) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List aside={<Aside />} actions={<ListActions />} sort={{ field: 'created_at', order: 'DESC' }} filters={<CampFilter />}  {...props}>
            {isSmall ?
                <SimpleList
                    primaryText={record => record && record.student.firstName + ' ' + record.student.lastName}
                    secondaryText={record => record && record.description + ' - ' + record.amount + ' RSD'}
                    tertiaryText={record => record && moment(record.created_at).format('D/MM/YYYY hh:m')}
                />
                :
                <Datagrid rowClick="edit">
                    <TextField source="student.firstName" label="First name" sortable={false} />
                    <ReferenceField source="student.id" reference="students" label="Last name" sortable={false}>
                        <TextField source="lastName" />
                    </ReferenceField>
                    <NumberField source="amount" />
                    <TextField source="description" />
                    <DateField source="created_at" locales="fr-FR" />
                    <TextField source="fiscalNumber" label="Fiscal" />

                </Datagrid>}
        </List>
    )
}

export const CampEdit = props => (
    <Edit title={<Title />} {...props} undoable={false}>
        <SimpleForm>
            <NumberInput source="amount" />
            <ReferenceInput label="Student" source="student" reference="students" perPage={200} sort={{ field: 'firstName', order: 'ASC' }} filter={{ active: true }}>
                <SelectInput optionText={record => record.firstName + ' ' + record.lastName} optionValue='id' />
            </ReferenceInput>
            <TextInput source="description" />
            <DateInput source="created_at" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
            <TextInput source="fiscalNumber" />
        </SimpleForm>
    </Edit>
);

export const CampCreate = props => (
    <Create {...props} undoable={false}>
        <SimpleForm>
            <NumberInput source="amount" />
            <ReferenceInput label="Student" source="student" reference="students" perPage={200} sort={{ field: 'firstName', order: 'ASC' }} filter={{ active: true }}>
                <SelectInput optionText={record => record.firstName + ' ' + record.lastName} optionValue='id' />
            </ReferenceInput>
            <SelectInput source="description" choices={[
                { id: 'plaćena I rata', name: 'plaćena I rata' },
                { id: 'plaćena II rata', name: 'plaćena II rata' },
                { id: 'plaćena III rata', name: 'plaćena III rata' },
            ]} label="Description" />
            <TextInput source="fiscalNumber" />
        </SimpleForm>
    </Create>
);
