/* eslint-disable react-hooks/exhaustive-deps */
// in src/MyAppBar.js
import * as React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getUsers, updateUser } from '../Services/Services'
import { toast } from 'react-toastify';
import useWindowFocus from 'use-window-focus';
import { getMe } from '../Services/Services'
import { userContext } from '../context/Context'

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const MyAppBar = props => {
    const classes = useStyles();
    const token = localStorage.getItem('token');
    const windowFocused = useWindowFocus();
    const [userMe, setUserMe] = React.useContext(userContext)

    React.useEffect(() => {

        token && getMe().then(res => setUserMe(res))

    }, [])

    React.useEffect(() => {

        windowFocused && userMe && getUsers(userMe.id).then(res => {
            res && res[0] && res[0].notifications && res[0].notifications[0] && res[0].notifications.forEach(msg => {
                toast.info(msg, {
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: msg,
                    onClose: () => {
                        updateUser(userMe.id, msg)
                    }
                })
            })

        })

    }, [windowFocused])

    return (
        <AppBar {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />

            <span className={classes.spacer} />
        </AppBar>
    );
};

export default MyAppBar;
