/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { useForm } from 'react-final-form';
import moment from 'moment';
import { getTraining } from '../Services/Services'

export default function Picker(props) {

    const [selectedDate, handleDateChange] = useState();
    const form = useForm()

    const startdate = () => {

        if (props.source === 'startDate') {
            const date = moment(selectedDate).format('YYYY-MM-DD HH:mm')
            return `&appointment_gte=` + date
        }
        else if (props && props.formData && props.formData.startDate) {
            const date = props.formData.startDate
            return `&appointment_gte=` + date
        }
        else return ''
    }

    const enddate = () => {

        if (props.source === 'endDate') {
            const date = moment(selectedDate).format('YYYY-MM-DD HH:mm')
            return `&appointment_lte=` + date
        }
        else if (props && props.formData && props.formData.endDate) {
            const date = props.formData.endDate
            return `&appointment_lte=` + date
        }
        else return ''
    }
    React.useEffect(() => {


        props && props.type && props.type === 'dateOnly' ?
            form.change(props.source, moment(selectedDate).format('YYYY-MM-DD HH:mm'))
            :
            form.change(props.source, moment(selectedDate).format('YYYY-MM-DD HH:mmZZ'))



        if (props && props.type && props.type === 'dateOnly') {

            getTraining(props.coachID, startdate(), enddate()).then(data => {
                const total = data && data.map(training => parseInt(training.duration))
                const result = total.reduce((a, b) => a + b, 0)
                form.change('totalHours', result / 60)
            })

        }

    }, [selectedDate])

    React.useEffect(() => {

        if (props && props.type && props.type === 'dateOnly') {
            if (props.source === 'startDate') handleDateChange(moment().format("01/MM/yyyy 00:00"))
            if (props.source === 'endDate') handleDateChange(moment().format("01/MM/yyyy 23:59"))
        }

        if (props && props.type && props.type === 'DateAndTime') {
            handleDateChange(moment().format('YYYY-MM-DD HH:mm'))
        }

    }, [])

    React.useEffect(() => {

        getTraining(props.coachID, startdate(), enddate()).then(data => {
            const total = data && data.map(training => parseInt(training.duration))
            const result = total.reduce((a, b) => a + b, 0)
            form.change('totalHours', result / 60)
        })

    }, [props && props.coachID && props.coachID])


    return (<>

        {props && props.type && props.type === 'dateOnly' ?
            <KeyboardDateTimePicker value={selectedDate} label={props.label} onChange={handleDateChange} format="DD/MM/yyyy HH:mm" inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }}
            />
            :
            <KeyboardDateTimePicker value={selectedDate} label="Appointment" onChange={handleDateChange} format="DD/MM/yyyy HH:mm" inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }}
            />
        }
    </>

    );
}