

import * as React from "react";
import { List, Create, Button, usePermissions, useListContext, useRefresh, sanitizeListRestProps, CreateButton, ExportButton, SimpleList, TopToolbar, Datagrid, CardActions, Toolbar, CheckboxGroupInput, SaveButton, Filter, BooleanField, TextField, BooleanInput, FormDataConsumer, SimpleForm, TextInput, Edit, SelectInput } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { PayButton, StudentTrainingList } from '../components/Buttons'
import Box from '@material-ui/core/Box';
import exportToVCard from '../components/exportToVCard'
import { makeStyles } from '@material-ui/core/styles';
// import ImportStudents from '../JSON/ImportStudents'
import SendSms from '../components/sendSms/SendSms'
import ButtonMUI from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { DateInput } from '../components/DateInput'
import useWindowSize from '../components/utils/useWindowsSize'
import { getSendAgrement, deactivateStudents, generateInvoices } from '../Services/Services'
import ClearAllIcon from '@material-ui/icons/ClearAll';
import ReceiptIcon from '@material-ui/icons/Receipt';

const Title = ({ record }) => {
    return <span>{record.firstName + ' ' + record.lastName}</span>;
};

export const ActionsSave = props => {

    let history = useHistory()
    const { permissions } = usePermissions();

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.pristine ? true : false} />
            <ButtonMUI style={{ right: '100', marginLeft: '60px' }} variant="contained" size="small" color="primary" onClick={() =>
                history.push({
                    pathname: '/students/create',
                    state: {
                        lastName: props.record.lastName,
                        phone: props.record.phone,
                        email: props.record.email,
                    },
                })}>
                <FileCopyIcon />
                Duplicate
            </ButtonMUI>
            {permissions === 'SuperAdmin' && props.record.agrement === false && <ButtonMUI style={{ right: '100', marginLeft: '10px' }} variant="contained" size="small" color="primary" onClick={() => {
                getSendAgrement(props.record.id).then(res => alert(res))
            }}>
                Agrement
            </ButtonMUI>}

            <CardActions />
        </Toolbar>
    )
};

export const ActionsSaveCreate = props => (
    <Toolbar {...props} >
        <SaveButton disabled={props.pristine ? true : false} />
        <CardActions />
    </Toolbar>
);

const exporter = students => {
    exportToVCard(students)
}

const StudentFilter = (props) => (
    <Filter {...props}>
        <TextInput source="_q" label="Search" alwaysOn />
        <BooleanInput label="Active" source="active" />
        <BooleanInput source="payed" label="Payed" />
        <BooleanInput source="payPerTraining" label="Pay per training" />
        <SelectInput source="scheduled_contains" choices={[
            { id: 'DIF', name: 'DIF' },
            { id: 'Arena', name: 'Arena' },
            { id: 'NBG', name: 'NBG' },
            { id: 'Hram', name: 'Hram' },
            { id: 'NS', name: 'NS' },
            { id: 'Individual', name: 'Individual' },

        ]} label="Location" />
        <BooleanInput source="agrement" />
    </Filter>
);

const ListActions = (props) => {
    const { width } = useWindowSize();
    const {
        className,
        filters,
        maxResults,
        permissions,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();
    const refresh = useRefresh();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
                exporter={exporter}
                label="Phonebook"
            />
            {permissions === 'SuperAdmin' && <SendSms filterValues={props.filterValues} />}
            {width > 400 && permissions === 'SuperAdmin' &&

                <Button
                    onClick={() => {
                        deactivateStudents().then(res => alert(res) & refresh())
                    }}
                    label="Deactivate"
                >
                    <ClearAllIcon />
                </Button>
            }
            {width > 400 && permissions === 'SuperAdmin' &&

                <Button
                    onClick={() => {
                        generateInvoices().then(res => alert(res.msg))
                    }}
                    label="Invoices"
                >
                    <ReceiptIcon />
                </Button>
            }
            {/* <ImportStudents /> */}

        </TopToolbar>
    );
};

const useStyles = makeStyles({
    perTrainingStyle: {
        color: 'orange'
    },
    notPayedStyle: {
        color: 'red'
    }
});

export const StudentsList = props => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();

    const TextFieldCustom = ({ record, ...rest }) =>
        record ? <TextField record={record} className={record && record.payPerTraining ? classes.perTrainingStyle : record.payed ? null : classes.notPayedStyle} {...rest} />
            : null;

    const BooleanFieldCustom = ({ record, ...rest }) =>
        record ? <BooleanField record={record} className={record && record.payPerTraining ? classes.perTrainingStyle : record.payed ? null : classes.notPayedStyle} {...rest} />
            : null;

    const BooleanFieldCustomAgrement = ({ record, ...rest }) =>
        record ? <BooleanField record={record}  {...rest} />
            : null;

    return (
        <List bulkActionButtons={false} filters={<StudentFilter />} sort={{ field: 'firstName', order: 'ASC' }} filterDefaultValues={{ active: true }} actions={<ListActions permissions={props.permissions} />} {...props}>
            {isSmall ?
                <SimpleList
                    rowStyle={(record, index) => record && record.payPerTraining ? { color: 'orange' } : record && record.payed ? null : { color: 'red' }}
                    primaryText={record => record && record.firstName + ' ' + record.lastName + `${record.agrement ? '' : ' 📝'}`}
                    secondaryText={record => record && `${record.email}, ${record.phone}`}
                    tertiaryText={record => record && record.active ? 'Active' : 'Inactive'}
                />
                :
                <Datagrid rowClick="edit">
                    <TextFieldCustom source="firstName" />
                    <TextFieldCustom source="lastName" />
                    <TextFieldCustom source="phone" />
                    <TextFieldCustom source="email" />
                    <TextFieldCustom source="note" />
                    <BooleanFieldCustom source="active" />
                    <BooleanFieldCustomAgrement source="agrement" />
                    {/* <BooleanFieldCustom source="payed" /> */}
                </Datagrid>
            }
        </List>
    )
};

export const StudentEdit = props => {

    const { permissions } = usePermissions();

    return (
        <Edit title={<Title />} {...props} undoable={false}>
            <SimpleForm toolbar={<ActionsSave />}>
                <TextInput source="firstName" />
                <TextInput source="lastName" />
                <TextInput source="phone" />
                <TextInput source="email" />
                {permissions === 'SuperAdmin' && <TextInput source="adress" />}
                {permissions === 'SuperAdmin' && <TextInput source="parent" />}
                {permissions === 'SuperAdmin' && <TextInput source="parentIDcard" label="Parent ID card" />}
                <SelectInput source="sex" choices={[
                    { id: 'male', name: 'male' },
                    { id: 'female', name: 'female' },
                ]} />
                <DateInput source="birthday" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
                <BooleanInput source="active" />
                <BooleanInput source="payPerTraining" label={'Pay per training'} />
                {permissions === 'SuperAdmin' && <BooleanInput source="agrement" />}
                <CheckboxGroupInput source="scheduled" label='Scheduled' choices={[
                    { id: 'DIF', name: 'DIF' },
                    { id: 'Arena', name: 'Arena' },
                    { id: 'NBG', name: 'NBG' },
                    { id: 'Hram', name: 'Hram' },
                    { id: 'NS', name: 'NS' },
                    { id: 'Individual', name: 'Individual' },
                ]} />

                <FormDataConsumer>
                    {({ formData }) => <React.Fragment>
                        <StudentTrainingList trainings={formData.trainings} />
                        <Box display='flex' flexDirection='column' width='140px'>
                            <PayButton student={formData} button={true} />
                        </Box>

                    </React.Fragment>}
                </FormDataConsumer>
                <TextInput source="note" multiline />


            </SimpleForm>
        </Edit>
    )
};

export const StudentCreate = props => {

    const location = useLocation();

    return (
        <Create {...props} undoable={false}>
            <SimpleForm toolbar={<ActionsSaveCreate />}>
                <TextInput source="firstName" />
                <TextInput source="lastName" initialValue={location?.state?.lastName ? location?.state?.lastName : ''} />
                <TextInput source="phone" initialValue={location?.state?.phone ? location?.state?.phone : ''} />
                <TextInput source="email" initialValue={location?.state?.email ? location?.state?.email : ''} />
                <SelectInput source="sex" choices={[
                    { id: 'male', name: 'male' },
                    { id: 'female', name: 'female' },
                ]} />
                <DateInput source="birthday" options={{ format: 'DD/MM/YYYY' }} inputVariant="filled" margin="dense" style={{ paddingBottom: '25px' }} />
                <BooleanInput source="active" />
                <BooleanInput source="payPerTraining" label={'Pay per training'} />
                <CheckboxGroupInput source="scheduled" label='Scheduled' choices={[
                    { id: 'DIF', name: 'DIF' },
                    { id: 'Arena', name: 'Arena' },
                    { id: 'NBG', name: 'NBG' },
                    { id: 'Hram', name: 'Hram' },
                    { id: 'NS', name: 'NS' },
                    { id: 'Individual', name: 'Individual' },
                ]} />
                <TextInput source="note" multiline />


            </SimpleForm>
        </Create>
    )
};