import * as React from "react";
import { List, CheckboxGroupInput, ReferenceArrayInput, Create, Filter, Datagrid, SimpleList, TextField, NumberField, ArrayField, SingleFieldList, ChipField, FormDataConsumer, SimpleForm, TextInput, Edit, ReferenceInput, ArrayInput, SimpleFormIterator, SelectInput } from 'react-admin';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import { DateTimeCustom } from '../components/DateTimeField'
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import { Dialog } from '@material-ui/core';
import { ActionsList, AssignButton, SaveButtonNotes, AddStudent } from '../components/Buttons'
import ItemsTable from '../components/TableListStudents'
import DateTimePicker from '../components/DateTimePicker'
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import { GenerateTopicDialog } from '../components/GenerateTopicDialog'
import { userContext } from '../context/Context'
import NatureIcon from '@material-ui/icons/Nature';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DomainIcon from '@material-ui/icons/Domain';

const Title = ({ record }) => {
    return <span>{record.location + ' ' + moment(record.appointment).format('D/MM/YYYY')}</span>;
};

const TrainingFilter = (props) => (
    <Filter {...props}>
        <TextInput source="_q" label="Search" alwaysOn />
    </Filter>
);

const switchAvatar = (record, index) => {

    if (record.location === 'DIF') return (<NatureIcon style={{ color: '#3e6d48' }} />)
    if (record.location === 'Arena') return (<LocationCityIcon style={{ color: '#b44c4c' }} />)
    if (record.location === 'NBG') return (<DomainIcon />)
    if (record.location === 'Hram') return (<AccountBalanceIcon style={{ color: '#a49a64' }} />)
    if (record.location === 'NS') return (<DomainIcon />)
    if (record.location === 'Individual') return (<AccountBalanceIcon style={{ color: '#a49a64' }} />)

};

const SimpleListRowStyle = (record, index) => ({
    outline: moment(record.appointment).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? '2px dashed grey' : '',
});

const DataGridListRowStyle = (record, index) => ({
    backgroundColor: moment(record.appointment).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? 'rgb(48, 48, 48)' : '',
});


export const TrainingList = props => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List bulkActionButtons={false} filters={<TrainingFilter />} actions={<ActionsList />} sort={{ field: 'appointment', order: 'DESC' }} {...props}>
            {isSmall ?
                <SimpleList
                    rowStyle={SimpleListRowStyle}
                    leftAvatar={switchAvatar}
                    primaryText={record => record.location}
                    secondaryText={record => moment(record.appointment).format('D/MM/YYYY, h:mm a') + ' - ' + moment(record.appointment).format('dddd')}
                    tertiaryText={record => record.duration + ' min'}
                />
                : <Datagrid rowClick="edit" rowStyle={DataGridListRowStyle} >
                    <DateTimeCustom source="appointment" label="Appointment" />
                    <TextField source="location" />
                    <NumberField source="duration" />
                    <TextField source="topic" />
                    <ArrayField source="coaches" sortable={false}><SingleFieldList linkType={false}><ChipField source="username" /></SingleFieldList></ArrayField>
                </Datagrid>}
        </List>
    )
};

export const TrainingEdit = props => {

    const [isLogged, setIsLogged] = React.useState(false)
    const [NoteDialog, setNoteDialog] = React.useState(false)
    const [userMe,] = React.useContext(userContext)

    return (
        <Edit title={<Title />} {...props} undoable={false}>
            <SimpleForm toolbar={false}>

                {!isLogged && <AssignButton
                    source="coaches"
                    setIsLogged={setIsLogged}
                    isLogged={isLogged}
                    setNoteDialog={setNoteDialog}
                />}


                <FormDataConsumer>
                    {({ formData }) => <React.Fragment>
                        <ReferenceArrayInput label="Coaches" reference="users" source="coaches">
                            <CheckboxGroupInput options={{
                                icon: <PersonIcon />,
                                checkedIcon: <PersonIcon />
                            }} choices={formData && formData.coaches && formData.coaches.map(x => ({ id: x.id, name: x.username }))} disabled />
                        </ReferenceArrayInput>
                        {formData.Notes && formData.Notes[0] ? <Badge badgeContent={'N'} color="error"> <Button disabled={isLogged ? false : true} variant="contained" color="primary" onClick={() => setNoteDialog(true)}>
                            Notes
                        </Button></Badge>
                            :
                            <Button variant="contained" color="primary" disabled={isLogged ? false : true} onClick={() => setNoteDialog(true)}>
                                Notes
                        </Button>
                        }
                        <TextInput source="everything" label="Location and time" format={() => moment(formData.appointment).format('D/MM/YYYY, h:mm a') + ' - ' + formData.location} fullWidth disabled />
                        <TextInput source="topic" disabled multiline fullWidth />
                        <Dialog open={NoteDialog} fullWidth >
                            <Box style={{ margin: '10px' }}>
                                <ArrayInput source="Notes">
                                    <SimpleFormIterator>
                                        <ReferenceInput label="Coach" source="author" reference="users" filter={{ id: userMe && userMe.id }}>
                                            <SelectInput optionText="username" optionValue="id" fullWidth />
                                        </ReferenceInput>
                                        <TextInput source="message" multiline label="Message" fullWidth />
                                    </SimpleFormIterator></ArrayInput>
                                <Box display='flex' justifyContent='end' paddingTop='30px'>
                                    <SaveButtonNotes setNoteDialog={setNoteDialog} formData={formData} />
                                    <Button style={{ marginLeft: '20px' }} variant="contained" color="primary" onClick={() => setNoteDialog(false)}>
                                        Close
                                </Button>
                                </Box>
                            </Box>
                        </Dialog>
                        <AddStudent formData={formData} isLogged={isLogged} />
                        <ItemsTable
                            fields={['Name']}
                            formData={formData}
                            data={formData.students && formData.students.sort((a, b) => a.firstName.localeCompare(b.firstName))}
                            isLogged={isLogged}
                        />
                    </React.Fragment>}
                </FormDataConsumer>


            </SimpleForm>
        </Edit>
    )
};

export const TrainingCreate = props => {

    const [openTopic, setOpenTopic] = React.useState(false)

    return (
        <Create {...props} undoable={false}>
            <SimpleForm redirect="list">
                <DateTimePicker source="appointment" type="DateAndTime" />
                <SelectInput source="location" choices={[
                    { id: 'DIF', name: 'DIF' },
                    { id: 'Arena', name: 'Arena' },
                    { id: 'NBG', name: 'NBG' },
                    { id: 'Hram', name: 'Hram' },
                    { id: 'NS', name: 'NS' },
                    { id: 'Individual', name: 'Individual' },
                ]} />
                <SelectInput source="duration" choices={[
                    { id: 60, name: '60 min' },
                    { id: 90, name: '90 min' },
                    { id: 120, name: '120 min' },
                ]} />

                <Button variant="contained" color="primary" onClick={() => setOpenTopic(true)}>
                    Generate topic
                                </Button>
                <GenerateTopicDialog openTopic={openTopic} setOpenTopic={setOpenTopic} />

                <TextInput source="topic" multiline fullWidth />
            </SimpleForm>
        </Create>
    )
};