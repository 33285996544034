import * as React from "react";
import { List, ReferenceField, Datagrid, SimpleList, Filter, TextField, DateField, TextInput } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';

const AgreementFilter = (props) => {

    return (
        <Filter {...props}>
            <TextInput source="_q" label="Search" alwaysOn />
        </Filter>
    )
};

export const AgreementList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (

        <List filters={<AgreementFilter />} sort={{ field: 'created_at', order: 'DESC' }} {...props}>
            {isSmall ?
                <SimpleList
                    primaryText={record => record && `${record.student.lastName}`}
                    secondaryText={record => record.agrementPath}
                    tertiaryText={record => record && record.fullName}
                />
                : <Datagrid rowClick="edit">
                    <TextField source="fullName" label="Name" />
                    <ReferenceField source="student.id" reference="students" label="Student">
                        <TextField source="lastName" />
                    </ReferenceField>
                    <TextField source="agrementPath" label="File name" />
                    <DateField source="created_at" locales="fr-FR" />

                </Datagrid>}
        </List>





    )
};
