export default {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        const request = new Request(process.env.REACT_APP_API + '/auth/local', {
            method: 'POST',
            body: JSON.stringify({
                identifier: username,
                password: password,
            }),
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((res) => {
                localStorage.setItem('token', res.jwt);
                localStorage.setItem('role', res.user.role.name);
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject("Role not found");
    },
};