
import { List, Create, Datagrid, Filter, TextField, SimpleForm, TextInput, Edit, SelectInput } from 'react-admin';

const Title = ({ record }) => {
    return <span>{record.Name}</span>;
};

const TopicFilter = (props) => {

    return (
        <Filter {...props}>
            <TextInput source="_q" label="Search" alwaysOn />
            <SelectInput source="type" choices={[
                { id: 'Technique', name: 'Technique' },
                { id: 'Movements', name: 'Movements' },
                { id: 'Concepts', name: 'Concepts' },
                { id: 'Psychology', name: 'Psychology' },
                { id: 'Abilities', name: 'Abilities' },
            ]} alwaysOn />
        </Filter>
    )
};


export const TopicList = props => (
    <List filters={<TopicFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="Name" />
            <TextField source="type" />
        </Datagrid>
    </List>
);

export const TopicEdit = props => (
    <Edit title={<Title />} {...props} undoable={false}> 
        <SimpleForm>
            <TextInput source="Name" multiline />
            <SelectInput source="type" choices={[
                { id: 'Technique', name: 'Technique' },
                { id: 'Movements', name: 'Movements' },
                { id: 'Concepts', name: 'Concepts' },
                { id: 'Psychology', name: 'Psychology' },
                { id: 'Abilities', name: 'Abilities' },
            ]} />

        </SimpleForm>
    </Edit>
);

export const TopicCreate = props => (
    <Create  {...props} undoable={false}>
        <SimpleForm redirect="list">
            <TextInput source="Name" multiline />
            <SelectInput source="type" choices={[
                { id: 'Technique', name: 'Technique' },
                { id: 'Movements', name: 'Movements' },
                { id: 'Concepts', name: 'Concepts' },
                { id: 'Psychology', name: 'Psychology' },
                { id: 'Abilities', name: 'Abilities' },
            ]} />
        </SimpleForm>
    </Create>
);