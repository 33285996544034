import * as React from "react";
import authProvider from './providers/authProvider'
import strapiProvider from './providers/strapiProvider'
import { Admin, Resource, Login } from 'react-admin';
import MyTheme from './theme/MyTheme'
import UserIcon from '@material-ui/icons/Group';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import ContactsIcon from '@material-ui/icons/Contacts';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PaymentIcon from '@material-ui/icons/Payment';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import { TrainingList, TrainingEdit, TrainingCreate } from './Resources/Trainings'
import { StudentsList, StudentEdit, StudentCreate } from './Resources/Students'
import { UserList, UserEdit } from './Resources/Users'
import { FinanceList, FinanceEdit } from './Resources/Finances'
import { PaymentList, PaymentEdit } from './Resources/Payments'
import { SalaryList, SalaryCreate } from './Resources/Saleries'
import { TopicList, TopicEdit, TopicCreate } from './Resources/Topics'
import { AgreementList } from './Resources/Agreements'
import { CampList, CampEdit, CampCreate } from './Resources/CampPayment'
import { fetchJson } from './providers/fetchJson'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyLayout from './Layout/MyLayout'
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MyContextProvider } from './context/Context'

const httpClient = (url, options = {}) => {

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchJson(url, options);
}

const dataProvider = strapiProvider(process.env.REACT_APP_API, httpClient)
const MyLoginPage = () => <Login />;

const App = () => {
  
  return (
    <>
      <MyContextProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Admin layout={MyLayout} theme={MyTheme} dataProvider={dataProvider} authProvider={authProvider} loginPage={MyLoginPage} >
            {permissions => [
              // Restrict access to the edit and remove views to admin only
              <Resource name="trainings" list={TrainingList} edit={TrainingEdit} create={TrainingCreate} icon={SportsKabaddiIcon} />,
              <Resource name="students" list={StudentsList} edit={StudentEdit} create={StudentCreate} icon={SupervisedUserCircleIcon} />,

              permissions === 'SuperAdmin'
                ? <Resource name="payments" list={PaymentList} edit={PaymentEdit} icon={PaymentIcon} />
                : <Resource name="payments" list={PaymentList} icon={PaymentIcon} />,

                permissions === 'SuperAdmin'
                ? <Resource name="camps" list={CampList} edit={CampEdit} create={CampCreate} icon={NaturePeopleIcon} />
                : <Resource name="camps" />,

              // Only include the categories resource for admin users
              permissions === 'SuperAdmin'
                ? <Resource name="finances" list={FinanceList} edit={FinanceEdit} icon={MonetizationOnIcon} />
                : <Resource name="finances" />,

              permissions === 'SuperAdmin'
                ? <Resource name="salaries" list={SalaryList} create={SalaryCreate} icon={MoneyOffIcon} />
                : <Resource name="salaries" />,
              <Resource name="topics" list={TopicList} edit={TopicEdit} create={TopicCreate} icon={CallToActionIcon} />
              ,
              permissions === 'SuperAdmin'
                ?
                <Resource name="agrements" list={AgreementList} icon={ContactsIcon} />
                : <Resource name="agrements" />,
              permissions === 'SuperAdmin'
                ?
                <Resource name="users" list={UserList} edit={UserEdit} icon={UserIcon} />
                : <Resource name="users" />,
            ]}

          </Admin>
          <ToastContainer />
        </MuiPickersUtilsProvider>
      </MyContextProvider>
    </>
  )
};

export default App;

