import axios from 'axios';
import authProvider from '../providers/authProvider'

export const getTopics = async (coachID) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/topics`, {
            method: 'GET',
            params: { coaches: coachID },
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getFinances = async (filterValues) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/finances?_limit=10000`, {
            method: 'GET',
            params: filterValues
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getCampsPayment = async (filterValues) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/camps`, {
            method: 'GET',
            params: filterValues
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getPayments = async (studentsID) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/payments?_sort=created_at:DESC&student=${studentsID}`, {
            method: 'GET',

        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getUsers = async (userId) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/users`, {
            method: 'GET',
            params: { id: userId }
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const updateUser = async (userId, msg) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/users/${userId}`, {
            method: 'PUT',
            data: {
                type: 'updateNotification',
                notifications: msg
            }
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getMe = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/users/me`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getSendAgrement = async (id) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/sendagrement?id=${id}`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const deactivateStudents = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/deactivate`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const generateInvoices = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/invoices`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const generateCampInvoices = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/campsinvoices`, {
            method: 'GET',
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getTraining = async (coachID, startdate, enddate) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/trainings?_sort=appointment:DESC&_start=0&_limit=100000` + startdate + enddate, {
            method: 'GET',
            params: { coaches: coachID }
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getSMSData = async (allFilters) => {

    const filters = allFilters ? allFilters : {}
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/students?_sort=firstName:ASC&_start=0&_limit=1000&`, {
            method: 'GET',
            params: filters
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

// TO DO - delete Import from old database
export const addStudent = async (data) => {

    const perTraining = () => {
        if (data.note) {
            const string = data.note
            const n = string.toLowerCase().search('pojedina')
            if (n > 0) return true
            else return false
        } else return false
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const res = re.test(String(email).toLowerCase());

        if (res) return email
        else return 'dodatiemail123456@yahoo.com'
    }

    const newStudent = {
        firstName: data.first_name && data.first_name,
        lastName: data.last_name && data.last_name,
        email: data.email && validateEmail(data.email),
        phone: data.phone && data.phone,
        sex: data.sex && data.sex === '0' ? 'male' : 'female',
        active: data.is_active && data.is_active === '1' ? true : false,
        birthday: data.birthday && data.birthday,
        note: data.note && data.note,
        payPerTraining: perTraining(),
        aplicationDate: data.application_date && data.application_date
    }
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/students`, {
            method: 'POST',
            data: newStudent
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};




export const sendRequest = async (url, options) => {
    const token = localStorage.getItem('token');
    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) headers['Authorization'] = 'Bearer ' + token

    try {
        return await axios(url, {
            headers,
            ...options
        });
    } catch (error) {

        if (error.response.status === 401 || error.response.status === 403) {
            authProvider.logout()
            window.location.replace(`https://pkskola.pksrbija.com/#/login`);
            throw new Error(error.response.data.message);
        }
        throw new Error(error.response.data.message);
    }
};
